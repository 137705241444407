
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/projectinfo";

@Options({
  name: "jiagouInfo",
})
export default class extends Vue {
  private deptId = "";
  private deptInfo = "";
  protected workerInfo(item: any) {
    this.$router.push({
      path: "/app/projectInfo/workerInfo",
      query: { userId: item.userId },
    });
  }

  getreqdata(id: any) {
    api
      .mobilepersondept({
        deptId: id,
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res) => {
        // console.log(res);
        this.deptInfo = res.data;
      });
  }

  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    this.deptId = route.query && route.query.deptId;
    api
      .mobilepersondept({
        projectId: this.$store.state.project.projectinfoDetail.id,
        deptId: this.deptId,
      })
      .then((res) => {
        this.deptInfo = res.data;
      });
  }
}
